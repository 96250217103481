<template>
  <div class="flex-grow flex justify-center items-center">
    <div
      class="bg-gray-100 shadow rounded-none xl:rounded-cu py-10 px-5 xl:min-w-550 flex flex-col"
    >
      <div class="w-full flex justify-center mb-5 mt-3">
        <img src="/logos/48/logo@2x.png" class="w-24" />
      </div>
      <input
        type="text"
        class="bg-white border border-solid border-gray-300 w-full py-3 px-2 rounded-cu"
        placeholder="Saisissez le code"
        v-model="code"
      />
      <div class="flex flex-col xl:flex-row xl:justify-between mt-5">
        <div class="mb-3 xl:mb-0 xl:mr-5">
          Si vous n'avez pas encore recu de code, appuyez sur
          <a
            href="#"
            @click="onSentSMS"
            class="mx-1"
            v-ga="$ga.commands.trackName.bind(this, 'sentSmsDownApp')"
            >envoyer SMS</a
          >.<br />(Veuillez vérifier que vous avez bien ajouté votre numéro de
          téléphone mobile dans les paramétres)
        </div>
        <button
          @click="onActiveAccount"
          type="button"
          class="bg-transparent border-2 border-solid border-dokBlue-ultra text-dokBlue-ultra px-5 py-2 rounded-cu cursor-pointer"
        >
          Confirmez
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      code: null
    };
  },
  methods: {
    ...mapActions("user", ["SENT_2FA_ACCOUNT", "ACTIVE_2FA_ACCOUNT"]),
    async onSentSMS() {
      this.$vs.loading({ background: "#000" });
      const data = await this.SENT_2FA_ACCOUNT({
        onData: ({ ok, message }) => {
          this.$vs.notify({
            time: 4000,
            // title: ok ? "Success" : "Error",
            text: message,
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: "icon-check"
          });
          this.$vs.loading.close();
        }
      });
    },
    async onActiveAccount() {
      let _this = this;
      this.$vs.loading({ background: "#000" });
      const data = await this.ACTIVE_2FA_ACCOUNT({
        token: this.code,
        onData: ({ ok, message }) => {
          if (ok) {
            let editlocal = this.$auth.getUserInfo();
            editlocal.isMobileVerified = true;
            this.$auth.setUserInfo(editlocal);
          }
          this.$vs.notify({
            time: 4000,
            // title: ok ? "Success" : "Error",
            text: message,
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: "icon-check"
          });
          setTimeout(() => {
            _this.$router.push("/");
          }, 4000);
          this.$vs.loading.close();
        }
      });
    }
  }
};
</script>

<style lang="scss">
button,
input {
  font-size: 16px;
}
</style>
